import MainMenu from '@/views/MainMenu.vue'
import type { RouteRecordRaw } from 'vue-router'

const eventsRoute: RouteRecordRaw = {
  path: '/events',
  name: 'events',
  component: MainMenu,
  redirect: 'marvel-machine',
  meta: {
    menuTitle: 'Events',
  },
  children: [
    {
      path: '/marvel-machine',
      name: 'marvel-machine',
      component: () => import('@/views/events/MarvelMachine.vue'),
      meta: {
        menuTitle: 'Marvel Machine',
        fullPage: true,
        seo: {
          description: 'Play to win 3 in-game items! Then double one of those items for 1,000 NX!',
        },
        gaPageType: 'marvel machine',
      },
    },
    {
      path: '__', // external
      name: 'maplestory-fest',
      redirect: '/maplestoryfest', // external
      meta: {
        menuTitle: 'MapleStory Fest',
      },
    },
  ],
}

export default eventsRoute
